import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
	},
	{
		path: '/about',
		name: 'About',
		component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
	},
	{
		path: '/branding',
		name: 'Branding',
		component: () => import(/* webpackChunkName: "branding" */ '@/views/Branding.vue')
	},
	{
		path: '/brochure',
		name: 'Brochure',
		component: () => import(/* webpackChunkName: "brochure" */ '@/views/Brochure.vue')
	},
	{
		path: '/design',
		name: 'Design',
		component: () => import(/* webpackChunkName: "design" */ '@/views/Design.vue')
	},
	{
		path: '/printing',
		name: 'Printing',
		component: () => import(/* webpackChunkName: "printing" */ '@/views/Printing.vue')
	},
	{
		path: '/portfolio',
		name: 'Portfolio',
		component: () => import(/* webpackChunkName: "portfolio" */ '@/views/Portfolio.vue')
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
})

export default router
