import Vue from 'vue'
import LoadScript from 'vue-plugin-load-script'
import VueMasonry from 'vue-masonry-css'
import App from './App.vue'
import './assets/css/tailwind.css'
import router from './router'

Vue.config.productionTip = false

Vue.use(LoadScript)
Vue.use(VueMasonry)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

