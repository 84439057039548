<template>
	<div id="app">
		<div v-if="'/about' == path" class="question"></div>
		<div v-if="'/about' != path" class="question hidden lg:block"></div>
		<router-view/>
		<Modal/>
	</div>
</template>

<script>
	import Modal from '@/components/Modal'

	export default {
		name: 'App',
		components: {
			Modal,
		},
		computed: {
			path () {
				return this.$route.path
			},
		},
	}
</script>

<style>
	@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Condensed:wght@300;400;500;600;700&family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,700;1,300&family=Merriweather:ital,wght@0,300;0,400;0,700;1,300&family=Roboto:wght@300;400;500;700&display=swap');
	@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
	
	body {
		overflow-x: hidden;
	}

	.question {
		@apply w-screen h-screen;
		position: absolute;
		z-index: -1;
	}

	.question:before {
		@apply opacity-10;
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-repeat: no-repeat;
		background-position: right top;
		background-image: url(~@/assets/img/question.png);
		background-color: #e2e8f0;
		z-index: -5;
	}

	.vue-tabs {
        @apply w-full flex flex-col text-gray-300;
    }
    .vue-tabs .left-vertical-tabs {
        @apply w-full font-bold mb-2 text-gray-400 font-condensed;
    }
    .vue-tabs a {
        @apply bg-gray-900 text-xs flex border-none p-2 rounded-none;
    }
    .vue-tabs a:hover {
        @apply bg-black text-gray-400;
    }
    .vue-tabs .active a {
        @apply bg-gray-100 text-gray-900;
    }
    .vue-tabs span {
        @apply w-full justify-start !important;
    }   
    .vue-tabs .nav-tabs-wrapper {
        @apply flex flex-wrap;
    }
    .vue-tabs .nav-stacked {
        @apply w-full flex flex-wrap border-none;
    }
    .vue-tabs .nav-stacked li {
        @apply m-1;
    }
    .vue-tabs .tab-content {
        @apply w-full pl-1;
    }

    @media (min-width: 1024px) {
        .vue-tabs {
            @apply flex-row;
        }
        .vue-tabs .left-vertical-tabs {
            @apply w-5/12 mb-0 text-xl;
        }
        .vue-tabs a {
            @apply p-6 text-base;
        }
        .vue-tabs .nav-stacked li {
            @apply w-full m-0 mb-2;
        }
        .vue-tabs .tab-content {
            @apply w-full pl-6;
        }
	}
	
	.masonry img {
        @apply mb-5;
    }
</style>
