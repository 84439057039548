<template>
	<div v-show="isOpen" @click="$emit('on-close')">
		<div class="modal-backdrop"
			:class="{open: isOpen}"
		></div>

		<div class="modal-container fixed inset-0 sm:pt-24 sm:px-8 lg:pt-0 lg:px-0 z-50 flex items-center overflow-y-scroll lg:overflow-y-auto h-screen">
			<div class="modal-dialog" :class="{open: isOpen}" @click.stop>
				<button 
					:class="!alert 
						? 'absolute top-5 right-5 sm:-right-5 sm:-top-5 bg-red-700 text-red-100 hover:bg-red-800 hover:tex-red-400 transform rotate-45 p-2 shadow-lg'
						: 'absolute top-1 right-1 text-gray-100 hover:tex-gray-300 transform rotate-45 p-2'
					"
					@click="$emit('on-close')"
                >
					<div class="transform rotate-45">
						<i :class="alert ? 'close-small' : '' " class="gg-close"></i>
					</div>
				</button>
				<div class="modal-title" v-if="title">{{ title }}</div>
				<div class="modal-body h-screen lg:h-auto">
					<slot />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			isOpen: Boolean,
			alert: Boolean,
			title: String,
		},
	}
</script>

<style scoped>
	.modal-backdrop {
		@apply fixed z-40 inset-0 opacity-85 bg-gray-900;
	}
	.modal-dialog {
		@apply relative mx-auto w-auto max-w-5xl;
	}
	.modal-title {
		font-weight: bold;
		font-size: 1.3rem;
		margin-bottom: 1rem;
		color: rgb(100, 100, 100);
	}
	.modal-body {
		color: rgb(180, 180, 180);
	}
	.close-small {
		--ggs: .75
	}
</style>