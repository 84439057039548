<template>
	<Modal :isOpen="!!component" :title="title" :alert="alert" @on-close="handleClose">
		<component :is="component" @on-close="handleClose" v-bind="props" />
	</Modal>
</template>

<script>
	import { ModalBus } from '@/eventBus'
	import Modal from '@/components/common/Modal'
	
	export default {
		components: { 
			Modal 
		},
		data () {
			return {
				component: null,
				title: '',
				alert: false,
				props: null
			}
		},
		created () {
			ModalBus.$on('open', ({ component, alert, title = '', props = null }) => {
				this.component = component
				this.alert = alert
				this.title = title
				this.props = props
			})
			document.addEventListener('keyup', this.handleKeyup)
		},
		beforeDestroy () {
			document.removeEventListener('keyup', this.handleKeyup)
		},
		methods: {
			handleClose () {
				this.component = null
			},
			handleKeyup (e) {
				if (e.keyCode === 27) this.handleClose()
			}
		}
	}
</script>